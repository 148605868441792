import React, { useEffect, useState } from 'react';

import VisuelComponent from '../../components/ContentType/VisuelComponent/VisuelComponent';
import { Button } from '../Button/Button';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';

import './DiscoverOfferBox.scss';

const DiscoverOfferBox = ({
  visuel,
  alt,
  label = 'Découvrez nos offres-',
  link = '/',
  target = '_self',
}) => {
  const intl = useIntl();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth < 1024 ? true : false);
  }, []);

  return (
    <div className="discover_offer_box_component">
      <VisuelComponent
        visuel={visuel}
        alt={alt || ''}
        aspectRationValue={isMobile ? 374 / 430 : false}
      />
      <div className="overlay">
        <Button
          primary={true}
          label={label}
          link={link}
          target={target}
          aria-label={label + ' - ' + intl.formatMessage({ id: 'common.new_tab' })}
        />
      </div>
    </div>
  );
};

DiscoverOfferBox.defaultProps = {
  //label: 'Default Label',
};

export default DiscoverOfferBox;
