import React, { useEffect, useState } from "react";
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';

import { IconRoundedFacebook, IconRoundedLinkedin, IconRoundedX } from '../../Atoms/Icons/Icons'


import "./SocialShare.scss";

var classNames = require('classnames');

const SocialShare = ({ title, linkTo, vertical, hidemobile }) => {
  const intl = useIntl();
  const [urlShare, setUrlShare] = useState();
  useEffect(() => {
    setUrlShare(window.location.origin + linkTo);
  }, []);


  const facebookShare = (e) => {
    e.preventDefault();
    var winTop = (screen.height / 2) - (window.innerHeight / 2);
    var winLeft = (screen.width / 2) - (window.innerWidth / 2);
    window.open('https://www.facebook.com/sharer.php?u=' + encodeURIComponent(urlShare), 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + window.innerWidth / 2 + ',height=' + window.innerHeight / 2);
    return false;

  }
  const twitterShare = (e) => {
    e.preventDefault();
    window.open("https://twitter.com/share?url=" + encodeURIComponent(urlShare + "/") + "&text=" + title, "twitter", 'height=500, width=700, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no');
  }
  const linkedinShare = (e) => {
    e.preventDefault();
    const url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(urlShare) + '+&title=' + title + '&summary=&source=LinkedIn'
    window.open(url, "LinkedIn", "height=285,width=550,resizable=1");

  }
  return (
    <ul className={classNames('social_share_component', { 'vertical': vertical, 'hidemobile': hidemobile })}>
      <li>
        <a href="#" onClick={facebookShare} aria-label={intl.formatMessage({ id: 'ariaLabel.share_facebook' }) + ' ' + title}>
          <IconRoundedFacebook aria-hidden="true" />
        </a>
      </li>
      <li>
        <a href="#" onClick={twitterShare} aria-label={intl.formatMessage({ id: 'ariaLabel.share_twitter' }) + ' ' + title}>
          <IconRoundedX aria-hidden="true" />
        </a>
      </li>
      <li>
        <a href="#" onClick={linkedinShare} aria-label={intl.formatMessage({ id: 'ariaLabel.share_linkedin' }) + ' ' + title}>
          <IconRoundedLinkedin aria-hidden="true" />
        </a>
      </li>
    </ul>
  )
}

SocialShare.defaultProps = {
  title: 'Default Title',
  link: '/default'
};

export default SocialShare;
